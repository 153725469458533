<template>
    <v-container class="pa-8">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
        <v-icon>mdi-email-send</v-icon>
        Send Notifications
        
    </div>
    <v-row> 
        <v-col class="col-4">
            <v-card :elevation="0">
                <v-tabs vertical v-model="tab">
                    <v-tab v-for="tabs in tabActions" :key="tabs" active-class="text-primary primary lighten-5">
                        {{tabs}}
                    </v-tab>
                </v-tabs>
            </v-card>
            <v-card :elevation="0" v-show="tab == 0" class="pa-4 mt-4">
                <span class="font-weight-regular text-body-1"><b> Select a Message Template: </b>
                <v-chip-group column v-model="selectedTemplate" active-class="primary--text" @change="changeTemplate">
                    <v-chip small v-for="template in MessageTemplates" :key="template['.key']" :value="template">{{template.SubjectTitle}}</v-chip>
                </v-chip-group>
                </span>
            </v-card>
        </v-col>
        <v-col class="col pa-6">
            <div class="text-h6 font-weight-bold grey--text text--darken-2 mb-4">
                {{tabActions[tab]}}
            </div>
            <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat class="pa-4">
                    <div>
                        <v-combobox
                            v-model="toEmail"
                            :items="returnToAddresses"
                            item-text="text"
                            item-value="value"
                            chips
                            outlined
                            label="Select Email Address"
                            multiple
                            :loading="sendingState"
                            :disabled="sendingState"

                        >
                            <template v-slot:selection="{ item, select, selected }">
                                <v-chip
                                    :input-value="selected"
                                    close
                                    small
                                    :disabled="sendingState"
                                    @click="select"
                                    @click:close="removeToEmail(item)"
                                >
                                    <strong>{{ item.text }}</strong>&nbsp;
                                </v-chip>
                            </template>
                        </v-combobox>
                    </div>
                    <div>
                        <v-text-field
                            v-model="subjectTitle"
                            label="Subject Title"
                            outlined
                            :loading="sendingState"
                            :disabled="sendingState"
                        ></v-text-field>
                    </div>
                    <div>
                        <v-textarea
                        filled
                        label="Message"
                        v-model="toMessage"
                        :loading="sendingState"
                        :disabled="sendingState"
                        ></v-textarea>
                    </div>
                    <div class="row px-4">
                        <v-btn color="grey" text class="col-4" @click="resetNotificationForm()">Reset Form</v-btn>
                        <v-btn color="primary" class="col ml-2" @click="sendEmail()">Send Notifications</v-btn>
                    </div>
                </v-card>

                
            </v-tab-item>
            </v-tabs-items>
            
        </v-col>
    </v-row>
    <div v-html="html"></div>
    </v-container>
</template>
<script>
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default {
    data: () => ({
        tab: 0,
        tabActions: ['Compose Notifications','Sent Notifications','Notification Settings'],
        toEmail: [],
        toMessage: '',
        subjectTitle: '',
        html: '',
        sendingState: false,
        selectedTemplate: null
    }),
    firestore(){
      return {
        Departments: this.$db.collection('Departments'),
        UserAccounts: this.$db.collection('UserAccounts'),
        MessageTemplates: this.$db.collection('MessageTemplates')
      }
    },    
    computed:{
        returnToAddresses(){
            let users = this.UserAccounts.map(a=>{
                return {
                    text: `${a.emailAdd} - ${a.department}`,
                    value: a.emailAdd,
                }
            })
            let all = {text: 'All System Users',value: 'All'}

            if(this.checkIfAllIsSelected()){
                return [all]
            } else {
                users.push(all)
                return users.reverse()
            }
        },
        returnDay(){
            let time = new Date()
            let moment = this.$moment(time).format('HH')
            if(moment < 12) return 'Good Morning'
            if(moment == 12) return 'Good Noon'
            if(moment >= 13 && moment < 16) return 'Good Afternoon'
            if(moment >= 16) return 'Good Evening'
            return ''
        },
    },
    methods:{
        checkIfAllIsSelected(){
            let index = this.$lodash.findIndex(this.toEmail,a=>{
                return a.value == 'All'
            })
            if(index > -1){
                this.toEmail = [{text: 'All System Users',value: 'All'}]
                return true
            }

            return false
        },
        removeToEmail(item){
            let index = this.$lodash.findIndex(this.toEmail,a=>{
                return item.value == a
            })
            console.log(index,'index')
            this.toEmail.splice(index,1)
        },
        changeTemplate(){
            let template = this.selectedTemplate
            if(template){
                this.toEmail = template.Receivers
                this.toMessage = template.Message
                this.subjectTitle = template.SubjectTitle
            } else {
                this.resetNotificationForm()
            }

        },
        returnName(email){
            let split = email.split('.')
            return split[0]
        },
        async confirmSendingEmail(){

            if(this.toEmail.length == 0 || this.subjectTitle == '' || this.toMessage == ''){
                this.$dialog.warning({
                    text: `Please fill up all fields`,
                    title: `Incomplete Form`
                })
                return false
            }

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to send this notification?`,
                title: `Confirm Sending Notification - ${this.subjectTitle}`
            })

            if(confirm == false) return false

            return true
        },
        async sendEmail(){
            this.sendingState = true
            let confirm = await this.confirmSendingEmail() 
            if(confirm == false) {
                this.sendingState = false
                return
            } else {

            
            let mapReceivers = this.toEmail.map(a=>{
                return a.value
            })

            if(mapReceivers[0] !== 'All'){

                    mapReceivers.forEach(a=>{

                    let htmlTemplate = this.returnHTMLTemplate(a) 
                    
                    let data = {
                        service_id: 'service_x05w5nw',
                        template_id: 'template_nbbk4m1',
                        user_id: 'user_oKqLqlrzitHpPD7VGgTER',
                        template_params: {
                            subject: this.subjectTitle,
                            to_email: a,
                            html_template: htmlTemplate
                        },
                    }

                    this.sendViaAPI(data)
                        
                    })
                } else {
                    console.log('sending to all')
                    let receivers = this.UserAccounts.map(a=>{
                        return a.emailAdd
                    })

                    console.log(receivers.join(',').toString())


                    let htmlTemplate = this.returnHTMLTemplate('Everyone.all@sumthing.com') 
                    
                    let data = {
                        service_id: 'service_x05w5nw',
                        template_id: 'template_nbbk4m1',
                        user_id: 'user_oKqLqlrzitHpPD7VGgTER',
                        template_params: {
                            subject: this.subjectTitle,
                            to_email: receivers.join(',').toString(),
                            html_template: htmlTemplate
                        },
                    }

                    // receivers.join(',').toString()

                    this.sendViaAPI(data)

                }

            }
        },
        async sendViaAPI(data){
            let options = {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                data: JSON.stringify(data),
                url: 'https://api.emailjs.com/api/v1.0/email/send'
            }
            
            await this.$axios(options).then(async(res)=>{
                console.log(res,'res')
                this.$dialog.notify.success(`Success Sending Email - ${this.subjectTitle}`, {
                    position: 'bottom-right',
                    timeout: 3000
                })  

                if(this.selectedTemplate == null){
                    await this.saveAsTemplate()
                }
                this.sendingState = false
                this.resetNotificationForm()
                
            }).catch(err=>{
                console.log(err,'err')
            })
        },
        resetNotificationForm(){
            this.toEmail = []
            this.toMessage = ''
            this.subjectTitle = ''
            this.selectedTemplate = null
        },
        async saveAsTemplate(){
            let self = this
            const confirm = await this.$dialog.confirm({
                text: `Do you want to send this notification as a message template for later?`,
                title: `Confirm Saving Template - ${this.subjectTitle}`
            })

            if(confirm) {
                let template = {
                    Receivers: this.toEmail,
                    SubjectTitle: this.subjectTitle,
                    Message: this.toMessage,
                }
                self.$db.collection('MessageTemplates').add(template)
                .then(async()=>{
                    self.$dialog.notify.success(`Success Saving Message Template ${self.subjectTitle}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                })
            } 
        },
        returnHTMLTemplate(email){
            let html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                        <head>
                        <!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
                        <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                        <meta content="width=device-width" name="viewport"/>
                        <!--[if !mso]><!-->
                        <meta content="IE=edge" http-equiv="X-UA-Compatible"/>
                        <!--<![endif]-->
                        <title></title>
                        <!--[if !mso]><!-->
                        <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css"/>
                        <!--<![endif]-->
                        <style type="text/css">
                                body {
                                    margin: 0;
                                    padding: 0;
                                }

                                table,
                                td,
                                tr {
                                    vertical-align: top;
                                    border-collapse: collapse;
                                }

                                * {
                                    line-height: inherit;
                                }

                                a[x-apple-data-detectors=true] {
                                    color: inherit !important;
                                    text-decoration: none !important;
                                }
                            </style>
                        <style id="media-query" type="text/css">
                                @media (max-width: 620px) {

                                    .block-grid,
                                    .col {
                                        min-width: 320px !important;
                                        max-width: 100% !important;
                                        display: block !important;
                                    }

                                    .block-grid {
                                        width: 100% !important;
                                    }

                                    .col {
                                        width: 100% !important;
                                    }

                                    .col_cont {
                                        margin: 0 auto;
                                    }

                                    img.fullwidth,
                                    img.fullwidthOnMobile {
                                        max-width: 100% !important;
                                    }

                                    .no-stack .col {
                                        min-width: 0 !important;
                                        display: table-cell !important;
                                    }

                                    .no-stack.two-up .col {
                                        width: 50% !important;
                                    }

                                    .no-stack .col.num2 {
                                        width: 16.6% !important;
                                    }

                                    .no-stack .col.num3 {
                                        width: 25% !important;
                                    }

                                    .no-stack .col.num4 {
                                        width: 33% !important;
                                    }

                                    .no-stack .col.num5 {
                                        width: 41.6% !important;
                                    }

                                    .no-stack .col.num6 {
                                        width: 50% !important;
                                    }

                                    .no-stack .col.num7 {
                                        width: 58.3% !important;
                                    }

                                    .no-stack .col.num8 {
                                        width: 66.6% !important;
                                    }

                                    .no-stack .col.num9 {
                                        width: 75% !important;
                                    }

                                    .no-stack .col.num10 {
                                        width: 83.3% !important;
                                    }

                                    .video-block {
                                        max-width: none !important;
                                    }

                                    .mobile_hide {
                                        min-height: 0px;
                                        max-height: 0px;
                                        max-width: 0px;
                                        display: none;
                                        overflow: hidden;
                                        font-size: 0px;
                                    }

                                    .desktop_hide {
                                        display: block !important;
                                        max-height: none !important;
                                    }
                                }
                            </style>
                        </head>
                        <body class="clean-body" style="margin: 0; padding: 0; padding-top: 24px; -webkit-text-size-adjust: 100%; background-color: #ffd600;">
                        <!--[if IE]><div class="ie-browser"><![endif]-->
                        <table bgcolor="#ffd600" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="table-layout: fixed; vertical-align: top; min-width: 320px; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffd600; width: 100%;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td style="word-break: break-word; vertical-align: top;" valign="top">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#ffd600"><![endif]-->
                        <div style="background-color:transparent;">
                        <div class="block-grid" style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
                        <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:0px;"><![endif]-->
                        <div class="col num12" style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:0px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <div align="center" class="img-container center fullwidth" style="padding-right: 0px;padding-left: 0px;">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]-->
                        <div style="font-size:1px;line-height:25px"></div><img align="center" alt="Image" border="0" class="center fullwidth" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Frounder-up.png?alt=media&token=536f3424-2044-4e30-b5a3-175b6f3bcbef" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 600px; display: block;" title="Image" width="600"/>
                        <!--[if mso]></td></tr></table><![endif]-->
                        </div>
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                        </div>
                        </div>
                        </div>
                        <div style="background-color:transparent;">
                        <div class="block-grid" style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: #FFFFFF;">
                        <div style="border-collapse: collapse;display: table;width: 100%;background-color:#FFFFFF;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#FFFFFF"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#FFFFFF;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                        <div class="col num12" style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <div align="center" class="img-container center" style="padding-right: 0px;padding-left: 0px;">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><img align="center" alt="Image" border="0" class="center" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Fperahub-logo.png?alt=media&token=461c988b-234e-45be-9ef7-82634da740c1" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 250px; display: block;" title="Image" width="250"/>
                        <!--[if mso]></td></tr></table><![endif]-->
                        </div>
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, sans-serif"><![endif]-->
                        <div style="color:#555555;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;line-height:1.5;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div style="font-size: 12px; line-height: 1.5; color: #555555; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 18px;">
                        <p style="font-size: 14px; line-height: 1.5; text-align: center; word-break: break-word; mso-line-height-alt: 21px; margin: 0;">ORMP System Notification</p>
                        </div>
                        </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                        </div>
                        </div>
                        </div>
                        <div style="background-color:transparent;">
                        <div class="block-grid" style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: #FFFFFF;">
                        <div style="border-collapse: collapse;display: table;width: 100%;background-color:#FFFFFF;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#FFFFFF"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#FFFFFF;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:5px;"><![endif]-->
                        <div class="col num12" style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:0px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, sans-serif"><![endif]-->
                        <div style="color:#0D0D0D;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div style="font-size: 12px; line-height: 1.2; color: #0D0D0D; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 14px;">
                        <p style="font-size: 28px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 34px; margin: 0;"><span style="font-size: 28px;"><strong><span style="font-size: 28px;">${this.returnDay} ${capitalize(this.returnName(email))},</span></strong></span><br/><span style="font-size: 28px;">${this.subjectTitle}</span></p>
                        </div>
                        </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                        <div align="center" class="img-container center">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="" align="center"><![endif]--><img align="center" alt="Image" border="0" class="center" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Fdivider.png?alt=media&token=85ebfe13-12ce-4e5d-af25-b07e5c11139d" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 316px; display: block;" title="Image" width="316"/>
                        <!--[if mso]></td></tr></table><![endif]-->
                        </div>
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Tahoma, sans-serif"><![endif]-->
                        <div style="color:#555555;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;line-height:1.5;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <div style="font-size: 12px; line-height: 1.5; color: #555555; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 18px;">
                        <p style="font-size: 14px; line-height: 1.5; text-align: center; word-break: break-word; mso-line-height-alt: 21px; margin: 0; padding:16px;">${this.toMessage}</p>
                        </div>
                        </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                        <div align="center" class="button-container" style="padding-top:25px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"><tr><td style="padding-top: 25px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:46.5pt; width:177pt; v-text-anchor:middle;" arcsize="7%" stroke="false" fillcolor="#0068a5"><w:anchorlock/><v:textbox inset="0,0,0,0"><center style="color:#ffffff; font-family:Tahoma, sans-serif; font-size:16px"><![endif]-->
                        <div style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#0068a5;border-radius:4px;-webkit-border-radius:4px;-moz-border-radius:4px;width:auto; width:auto;;border-top:1px solid #0068a5;border-right:1px solid #0068a5;border-bottom:1px solid #0068a5;border-left:1px solid #0068a5;padding-top:15px;padding-bottom:15px;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;"><span style="padding-left:15px;padding-right:15px;font-size:16px;display:inline-block;"><span style="font-size: 16px; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;">GO TO ORMP SYSTEM</span></span></div>
                        <!--[if mso]></center></v:textbox></v:roundrect></td></tr></table><![endif]-->
                        </div>
                        <table border="0" cellpadding="0" cellspacing="0" class="divider" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 30px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="divider_content" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; width: 100%;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
                        </tr>
                        </tbody>
                        </table>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                        </div>
                        </div>
                        </div>
                        <div style="background-color:transparent;">
                        <div class="block-grid three-up" style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: #525252;">
                        <div style="border-collapse: collapse;display: table;width: 100%;background-color:#525252;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#525252"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="200" style="background-color:#525252;width:200px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                        <div class="col num4" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 200px; width: 200px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:0px; padding-bottom:0px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <table cellpadding="0" cellspacing="0" class="social_icons" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td style="word-break: break-word; vertical-align: top; padding-top: 15px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
                        <table align="center" cellpadding="0" cellspacing="0" class="social_table" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;" valign="top">
                        <tbody>
                        <tr align="center" style="vertical-align: top; display: inline-block; text-align: center;" valign="top">
                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://www.facebook.com/" target="_blank"><img alt="Facebook" height="32" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Ffacebook2x.png?alt=media&token=21823a5b-83bd-4fed-a607-f1d2dc2e10eb" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;" title="Facebook" width="32"/></a></td>
                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://twitter.com/" target="_blank"><img alt="Twitter" height="32" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Ftwitter2x.png?alt=media&token=a722a069-f64a-4248-a6c6-36690a81e15d" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;" title="Twitter" width="32"/></a></td>
                        <td style="word-break: break-word; vertical-align: top; padding-bottom: 5px; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://plus.google.com/" target="_blank"><img alt="Google+" height="32" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Fgoogleplus2x.png?alt=media&token=07db1aa5-8583-43c8-921f-4710b9e5a193" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;" title="Google+" width="32"/></a></td>
                        </tr>
                        </tbody>
                        </table>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td><td align="center" width="200" style="background-color:#525252;width:200px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                        <div class="col num4" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 200px; width: 200px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 0px; font-family: Tahoma, sans-serif"><![endif]-->
                        <div style="color:#ffd600;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;line-height:1.2;padding-top:20px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
                        <div style="font-size: 12px; line-height: 1.2; color: #ffd600; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 14px;">
                        <p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><span style="color: #ffffff; font-size: 12px;"><span style="font-size: 12px; color: #ffd600;">Tel.:</span> +39 . 000 . 000 . 000</span></p>
                        </div>
                        </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td><td align="center" width="200" style="background-color:#525252;width:200px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
                        <div class="col num4" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 200px; width: 200px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 20px; padding-bottom: 0px; font-family: Tahoma, sans-serif"><![endif]-->
                        <div style="color:#ffd600;font-family:Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif;line-height:1.2;padding-top:20px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
                        <div style="font-size: 12px; line-height: 1.2; color: #ffd600; font-family: Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif; mso-line-height-alt: 14px;">
                        <p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">Email: <span style="color: #ffffff; font-size: 12px;">ormp.petnet@gmail.com</span></p>
                        </div>
                        </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                        </div>
                        </div>
                        </div>
                        <div style="background-color:transparent;">
                        <div class="block-grid" style="min-width: 320px; max-width: 600px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
                        <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:transparent;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:5px;"><![endif]-->
                        <div class="col num12" style="min-width: 320px; max-width: 600px; display: table-cell; vertical-align: top; width: 600px;">
                        <div class="col_cont" style="width:100% !important;">
                        <!--[if (!mso)&(!IE)]><!-->
                        <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:0px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
                        <!--<![endif]-->
                        <div align="center" class="img-container center fullwidth" style="padding-right: 0px;padding-left: 0px;">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><img align="center" alt="Image" border="0" class="center fullwidth" src="https://firebasestorage.googleapis.com/v0/b/perahub-ormp-test.appspot.com/o/EmailTemplate%2Frounder-dwn.png?alt=media&token=5e04e6e7-907f-416a-a01b-6f5924a7e8c4" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 600px; display: block;" title="Image" width="600"/>
                        <!--[if mso]></td></tr></table><![endif]-->
                        </div>
                        <table border="0" cellpadding="0" cellspacing="0" class="divider" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 30px; padding-right: 30px; padding-bottom: 30px; padding-left: 30px;" valign="top">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="divider_content" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; width: 100%;" valign="top" width="100%">
                        <tbody>
                        <tr style="vertical-align: top;" valign="top">
                        <td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
                        </tr>
                        </tbody>
                        </table>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        <!--[if (!mso)&(!IE)]><!-->
                        </div>
                        <!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
                        </div>
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        <!--[if (IE)]></div><![endif]-->
                        </body>
                        </html>`
            return html
        },
    }
}
</script>
<style  scoped>
    div .v-tab  {
        justify-content: left;
    }
</style>